import React from "react";
import AnalystCoverage from "../templates/AnalystCoverage/analystCoverageMainBanner";
import CompanyGrid from "../templates/AnalystCoverage/CompanyGrid";

export default function CropSolutions() {
  return (
    <div>
      {/* <Header /> */}
      <AnalystCoverage />
      <CompanyGrid />
      {/* <Footer /> */}
    </div>
  );
}
