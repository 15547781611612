import React from "react";
import "./analystCoverageMainBanner.css";
import Image from "../../images/analystCoverageMainBanner.png";

export default function analystCoverageMainBanner() {
  return (
    <div>
      <div style={{ position: "relative", marginBottom: "-4px" }}>
        <img className="analystCoverage-home-image" alt="" src={Image} />
        <div className="analystCoverage-image-text-box">
          <div className="analystCoverage-image-background-text">
            <h1 className="analystCoverage-image-topic">Analyst Coverage</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
