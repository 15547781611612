import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./CompanyGrid.css";

import Image1 from "../../images/cal-logo.jpeg";
import Image2 from "../../images/lynear-logo.png";
import Image3 from "../../images/nation-lanka-logo.jpeg";
import Image4 from "../../images/asia-securities-logo.png";
import Image5 from "../../images/softlogic-logo.jpeg";
import Image6 from "../../images/ct-clsa-logo.jpeg";
import Image7 from "../../images/frontier-logo.jpeg";
import Image8 from "../../images/taprobane-logo.jpeg";

const data = [
  { name: "CAL Research", logo: Image1, aosDuration: 800 },
  { name: "Lynear Wealth Management", logo: Image2, aosDuration: 1000 },
  { name: "Nation Lanka Equities (Pvt) Ltd", logo: Image3, aosDuration: 1200 },
  { name: "Asia Securities", logo: Image4, aosDuration: 1400 },
  { name: "Softlogic Stockbrokers (Pvt) Ltd", logo: Image5, aosDuration: 1000 },
  { name: "CT CLSA Securities (Pvt) Ltd", logo: Image6, aosDuration: 1200 },
  { name: "Frontier Research", logo: Image7, aosDuration: 1400 },
  { name: "Taprobane Capital Plus (Pvt) Ltd", logo: Image8, aosDuration: 1600 },
];

const CompanyGrid = () => {
  useEffect(() => {
    AOS.init({
      once: false, // Only animate once
    });
  }, []);

  return (
    <div className="company-grid">
      <center>
        <div className="company-card">
          <div className="company-grid-container">
            {data.map((item, index) => (
              <div
                className="company-grid-card"
                key={index}
                data-aos="fade-up"
                data-aos-duration={item.aosDuration} // Use item.aosDuration here
              >
                <img src={item.logo} alt="" className="company-grid-logo" />
                {/* <div className="company-grid-title">{item.name}</div> */}
              </div>
            ))}
          </div>
        </div>
      </center>
    </div>
  );
};

export default CompanyGrid;
